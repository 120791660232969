import { createReducer, on } from '@ngrx/store';
import { set, reset } from './../actions/selected-element.actions';

export const initialState = '';
 
const _selectedElementReducer = createReducer(initialState,
  on(set, (state, action) => action.name) ,
  on(reset, state => initialState),
);

export function selectedElementReducer(state, action) {
    return _selectedElementReducer(state, action);
  }
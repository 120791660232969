<div class="options-elements">
        <div class="option" 
            (click)="GenerateNewItem('plane', 'info', [{ option: 'transparent', value: 'true'}, 
                                                       { option: 'src', value: '#info'},
                                                       { option: 'width', value: '0.6'},
                                                       { option: 'height', value: '0.6'}])">
            Informacja
        </div>
        <div class="option" 
            (click)="GenerateNewItem('plane', 'pin', [{ option: 'transparent', value: 'true'}, 
                                                       { option: 'src', value: '#pin'},
                                                       { option: 'width', value: '0.6'},
                                                       { option: 'height', value: '0.6'}])">
            Podróż
        </div>
</div>
<div class="choose-image-container">
    <div class="choose">
        <h3>Chcę zdjęcie</h3>
        <input type="checkbox" [checked]="wantImage" (change)="wantImageChange()" />
    </div>
    <div class="images" *ngIf="wantImage" >
        <div class="image" *ngFor="let img of galleryImages$ | async" [ngClass]="{ 'active' : activeImage === img.id }">
            <img src="{{img.img_url}}" alt="" (click)="setActive(img.id, img.img_url )">
        </div>
    </div>
</div>

<div class="editor-container" [ngStyle]="wantImage && {'top': '200px'}" >
    <quill-editor (onContentChanged)="editorElement($event)" [ngModel]="editorHtml"></quill-editor>
    <input type="button" value="Zapisz" (click)="saveInformation()" />
</div>
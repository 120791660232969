import { createReducer, on } from '@ngrx/store';
import { setCoordinates} from './../actions/coordinates.actions';

export const initialState = [];
 
const _coordinatesReducer = createReducer(initialState,
  on(setCoordinates, (state, action) => action.coords) ,
);

export function coordinatesReducer(state, action) {
    return _coordinatesReducer(state, action);
  }
import { Component, OnInit, Input } from '@angular/core';
import { Store, select  } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setCoordinates } from 'src/app/actions/coordinates.actions';

@Component({
  selector: 'coordinates',
  templateUrl: './coordinates.component.html',
  styleUrls: ['./coordinates.component.scss']
})
export class CoordinatesComponent implements OnInit {
  @Input() lat = '';
  @Input() lng = '';
  coords$:Observable<object>

  constructor(private store: Store<{ coords: object }>) {
    this.coords$ =  store.pipe(select('coords'));
  }

  ngOnInit(): void {
    this.coords$.subscribe((val) => {
      this.lat = val['lat'];
      this.lng = val['lng'];
    });
  }

  saveInformation = async() => {
    let data = {
      lat: this.lat,
      lng: this.lng
    }
    await this.store.dispatch(setCoordinates({ coords: data }));
  }
}

import { createReducer, on } from '@ngrx/store';
import { setImageBg } from './../actions/image-bg.actions';

export const initialState = null;
 
const _imageBgReducer = createReducer(initialState,
  on(setImageBg, (state, action) => action.name) ,
);

export function imageBgReducer(state, action) {
    return _imageBgReducer(state, action);
}
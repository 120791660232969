import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'moving',
  templateUrl: './moving.component.html',
  styleUrls: ['./moving.component.scss']
})
export class MovingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  moveElement(direction) {
    let getActiveEl = document.querySelector('.selected');
    let getX = getActiveEl['object3D'].position.x;
    let getY = getActiveEl['object3D'].position.y;
    let getZ = getActiveEl['object3D'].position.z;

    switch (direction) {
      case 'left':   
        getActiveEl.setAttribute('position', `${ getX - 0.05 } ${ getY } ${ getZ }`);
        break;

      case 'right':   
        getActiveEl.setAttribute('position', `${ getX + 0.05 } ${ getY } ${ getZ }`);
        break;

      case 'top':   
        getActiveEl.setAttribute('position', `${ getX } ${ getY + 0.05 } ${ getZ }`);
        break;

      case 'bottom':   
        getActiveEl.setAttribute('position', `${ getX } ${ getY - 0.05 } ${ getZ }`);
        break;

      case 'zoomin':   
        getActiveEl.setAttribute('position', `${ getX } ${ getY } ${ getZ + 0.05}`);
        break;

      case 'zoomout':   
        getActiveEl.setAttribute('position', `${ getX } ${ getY } ${ getZ - 0.05}`);
        break;
    
      default:
        break;
    }
  }

}

import { createReducer, on } from '@ngrx/store';
import { setImageId } from './../actions/image-id.actions';

export const initialState = 0;
 
const _imageIdReducer = createReducer(initialState,
  on(setImageId, (state, action) => action.name) ,
);

export function imageIdReducer(state, action) {
    return _imageIdReducer(state, action);
}
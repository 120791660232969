import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { reset } from './../../actions/selected-element.actions';
import { setActiveMenu, resetActiveMenu } from './../../actions/active-menu.actions';
import { removeVrElements } from './../../actions/vr-elements.actions';

import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() activeMenuTemp:string = '';
  selectedElement$:Observable<string>
  activeMenu$:Observable<string>
  duplicating$:Observable<string>
  imageBg$:Observable<string>
  vrElements$:Observable<object>
  tempvrElements: object
  lat:string = ''
  lng:string = ''
  coords$:Observable<object>
  
  constructor(private store: Store<{ coords: object, selectedElement: string, activeMenu: string, duplicating: string, imageBg: string, vrElements: object }>) {
    this.selectedElement$ = store.pipe(select('selectedElement'));
    this.activeMenu$ = store.pipe(select('activeMenu'));
    this.duplicating$ = store.pipe(select('duplicating'));
    this.imageBg$ = store.pipe(select('imageBg'));
    this.vrElements$ = store.pipe(select('vrElements'));
    this.coords$ =  store.pipe(select('coords'));
  }

  
  ngOnInit(): void {
    this.vrElements$.subscribe((data) => {
        this.tempvrElements = data;
    });
    this.coords$.subscribe((val) => {
      this.lat = val['lat'];
      this.lng = val['lng'];
    });
  }

  async handleClick(menu: string) {
      this.activeMenuTemp = menu;
      await this.store.dispatch(setActiveMenu({ name: menu }));
  }

  

  deleteSelectedElement() {
    let selectedItem = document.querySelector('.selected');
    let getID = selectedItem.getAttribute('data-id');
    this.store.dispatch(removeVrElements({ id: getID } ));

    selectedItem.remove();
    this.store.dispatch(reset());
  }

  getVRElements = () => {
    let items = document.querySelectorAll('.vr-el');
    let tempArr = [];

    items.forEach(el => {
      let pos = `${ el['object3D'].position.x } ${ el['object3D'].position.y } ${ el['object3D'].position.z }`;
      let rot = `${ el['object3D'].rotation.x * 57.2957795} ${ el['object3D'].rotation.y * 57.2957795} ${ el['object3D'].rotation.z * 57.2957795}`;
      let id = el.getAttribute( 'data-id');
      let type = el.getAttribute('data-type');
      let travel = el.getAttribute('data-travel');

      tempArr = [...tempArr, {
        id,
        position: pos,
        rotation: rot,
        src: type,
        travel: travel
      }]
    });

    return tempArr;
  }

  getVRPopups = () => {
    let items = document.querySelectorAll('.popup');
    let tempArr = [];

    if ( items.length > 0 ) {
        items.forEach(el => {
          let id = el.getAttribute( 'data-i');
          let imageID = el.querySelector('.image-container') ? el.querySelector('.image-container').getAttribute('data-id') : null;
          let imageSrc = el.querySelector('.image-container') ? el.querySelector('.image-container > img').getAttribute('src') : null;
          let text = el.querySelector('.text-container') ? el.querySelector('.text-container').innerHTML : null
    
          tempArr = [...tempArr, {
            id,
            imageID,
            imageSrc,
            text,
          }]
        });
    }
    
    return tempArr;
  }

  getCameraRotation = () => {
    let cam = document.querySelector('#cameraWrapper');

    let rot = `${cam['object3D'].rotation.x * 57.2957795} ${cam['object3D'].rotation.y * 57.2957795} ${cam['object3D'].rotation.z * 57.2957795}`;

    return rot;
  }

  saveVr = async () => {
    let queryString  = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getId = urlParams.get('srcLoad');
    var token = urlParams.get('token');
    let vrElements = this.getVRElements();
    let vrPopups = this.getVRPopups();
    let cameraRotation = this.getCameraRotation();
    let conentObj = {
        pins: vrElements,
        popups: vrPopups,
        cameraRotation: cameraRotation
    }
    let lat = this.lat;
    let lng = this.lng;
    
    await axios({
      method: 'post',
      url: `${ApiUrl()}/updateMainImageVr`,
      data: {
        imageID: getId,
        content: JSON.stringify(conentObj),
        lat,
        lng,
        token: token
      }
    }).then(async response => {
      console.log(response);
      alert('Zapisane !')
    })
  }

}

<nav class="main-nav" >
    <ul>
        <li (click)="handleClick( 'new-element')" [ngClass]="{'active': (activeMenu$ | async ) == 'new-element'}">
            Dodaj element
        </li>
        <li (click)="handleClick('moving')" [ngClass]="{'active': (activeMenu$ | async ) == 'moving'}">
            Przesuwanie
        </li>
        <li (click)="handleClick('rotation')" [ngClass]="{'active': (activeMenu$ | async ) == 'rotation'}">
            Rotacja
        </li>
        <li (click)="handleClick('edit')" [ngClass]="{'active': (activeMenu$ | async ) == 'edit'}" *ngIf="(selectedElement$ | async ) !== ''">
            Edytuj zawartość
        </li>
        <li (click)="handleClick('coords')" [ngClass]="{'active': (activeMenu$ | async ) == 'coords'}">
            Koordynaty
        </li>
        <li (click)="handleClick('upload')" [ngClass]="{'active': (activeMenu$ | async ) == 'upload'}" >
            Wgraj zdjęcie
        </li>
        <li (click)="saveVr()" *ngIf="(duplicating$ | async ) !== 'duplicating'">
            Zapisz Scenę 360
        </li>
    </ul>
    <div class="delete" *ngIf="(selectedElement$ | async ) !== '' ">
        <img src="./assets/img/bin.svg" (click)="deleteSelectedElement()">
    </div>
</nav>

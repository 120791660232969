import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { set, reset } from './../../../actions/selected-element.actions';
import { setVrElements } from './../../../actions/vr-elements.actions';
import { resetActiveMenu } from './../../../actions/active-menu.actions';


@Component({
  selector: 'options-elements',
  templateUrl: './options-elements.component.html',
  styleUrls: ['./options-elements.component.scss']
})
export class OptionsElementsComponent implements OnInit {

  selectedElement$:Observable<string>

  
  constructor(private store: Store<{ selectedElement: string, vrElements: object }>) {
    this.selectedElement$ = store.pipe(select('selectedElement'));
  }

  ngOnInit(): void {
  }

  getCursorPosition() {
    let cursor = document.querySelector('a-camera');
    let cursorRot = cursor['object3D'].rotation;
    let cursorPos = document.getElementById('clickPosition')['object3D'].getWorldPosition();

    return {
      rotation: {
        x: cursorRot['_x'],
        y: cursorRot['_y'],
        z: cursorRot['_z']
      },
      x: cursorPos['x'],
      y: cursorPos['y'],
      z: cursorPos['z']
    }
  }

  deleteSelectedElements() {
    let selected = document.querySelectorAll('.selected');
    if ( selected.length > 0 ) {
      selected.forEach(el => {
        el.classList.remove('selected');
        el.setAttribute( 'opacity', '1');
        
      })
    }
  }

  GenerateNewItem( primitve:string, type:string, attributes = []) {
    let nowDate = Date.now();

    let cursorPosition =  this.getCursorPosition();
    let elPos = `${ cursorPosition.x } ${ cursorPosition.y }  ${ cursorPosition.z }`;
    let elRot = `${ cursorPosition.rotation.x * 57.2957795} ${ cursorPosition.rotation.y * 57.2957795 } ${ cursorPosition.rotation.z * 57.2957795 }`;
    let This = this;
    This.deleteSelectedElements();
    let sceneEl = document.querySelector('a-scene');
    let newEl = document.createElement(`a-${ primitve }`);
        newEl.setAttribute('position', elPos);
        newEl.setAttribute('rotation', elRot);
        newEl.setAttribute( 'opacity', '0.80');
        newEl.setAttribute( 'data-id', nowDate.toString() );

        newEl.classList.add('selected');
        newEl.classList.add('vr-el');

        newEl.dataset.type = type;
        attributes.forEach( el => {
          newEl.setAttribute(el.option, el.value);
        })
        This.store.dispatch(set({ name: type } ));

        newEl.addEventListener('click', async function(e) {
          if ( !this.classList.contains('selected') ) {
            await This.store.dispatch(set({ name: 'pusto' } ));
            await This.deleteSelectedElements();
            await this.classList.add('selected');
            await this.setAttribute( 'opacity', '0.80');
            await This.store.dispatch(set({ name: type } ));
          } else {
            await this.classList.remove('selected');
            await this.setAttribute( 'opacity', '1');
            await This.store.dispatch(resetActiveMenu());
            await This.store.dispatch(reset());
          }
        })

    sceneEl.appendChild(newEl);

  }

  addBox() {
    let cursorPosition =  this.getCursorPosition();

    let sceneEl = document.querySelector('a-scene');
    let newBox = document.createElement('a-box');
        newBox.setAttribute('color', '#4CC3D9');
        newBox.setAttribute('position', `${ cursorPosition.x } ${ cursorPosition.y }  ${ cursorPosition.z }`);
        newBox.setAttribute('rotation', `${ cursorPosition.rotation.x * 57.2957795} ${ cursorPosition.rotation.y * 57.2957795 } ${ cursorPosition.rotation.z * 57.2957795 }`);

    sceneEl.appendChild(newBox);
  }

}

import { createReducer, on } from '@ngrx/store';
import { setUploadImg } from './../actions/upload-img.actions';

export const initialState = [];
 
const _uploadImgReducer = createReducer(initialState,
  on(setUploadImg, (state, action) => action.obj) ,
);

export function uploadImgReducer(state, action) {
    return _uploadImgReducer(state, action);
  }
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  activeMenu$:Observable<string>
  selectedElement$:Observable<string>
  constructor(private store: Store<{ selectedElement: string, activeMenu: string }>) {
    this.activeMenu$ = store.pipe(select('activeMenu'));
    this.selectedElement$ = store.pipe(select('selectedElement'));
  }
  
  ngOnInit(): void {
  }

}

<div class="upload">
    <label>Nazwa zdjęcia 360</label>
    <input type="text" class="imgName" (change)="changeImgName($event)" [value]="imgName" />
    <div class="file-upload">
        <input id="file" type="file" accept="image/*" (change)="onFileSelected($event, 'main')">
        <label for="file" class="btn-3">
            <span>Wybierz zdjęcie 360</span>
        </label>
    </div>
    <div class="file-upload">
        <input id="file2" type="file" accept="image/*" (change)="onFileSelected($event, 'preview')">
        <label for="file2" class="btn-3">
            <span>Wybierz zdjęcie (2d)</span>
        </label>
        <img *ngIf="previewImage" src="{{previewImage}}" alt="">
    </div>
    <label>Na liście VR</label>
    <select (change)="changeVisible($event)">
        <option [attr.selected]="visible == 'yes' ? true : null" value="yes">pokaż</option>
        <option [attr.selected]="visible == 'no' ? true : null" value="no">ukryj</option>
    </select>

    <input type="submit" class="refresh" (click)="onUpload()" value="Wgraj zdjęcie" />
    <p>{{uploadText}}</p>

    <br /> 

    <label>Lub zduplikuj istniejącą scenę</label>
    <select [(ngModel)]="duplicate">
        <option *ngFor="let vr of vrgalleryImages$ | async"  [ngValue]="vr" >{{ vr.name_img }}</option>
    </select>
    <input type="submit" class="refresh" (click)="duplicateScene()" value="Zduplikuj" />
</div>
<div class="rotating">
    <div class="col">
        <img src="./assets/img/rotate-arrow.svg" class="arrow left" alt="lewo" (click)="moveElement('zoomin')">
    </div>
    <div class="col">
        <img src="./assets/img/rotate-arrow.svg" class="arrow top" alt="góra" (click)="moveElement('left')">
        <img src="./assets/img/rotate-arrow.svg" class="arrow bottom" alt="dół" (click)="moveElement('right')">
    </div>
    <div class="col">
        <img src="./assets/img/rotate-arrow.svg" class="arrow right" alt="prawo" (click)="moveElement('zoomout')">
    </div>
</div>
<div class="zooming">
    <div class="btn" (click)="moveElement('top')">
        Skos +
    </div>
    <div class="btn" (click)="moveElement('bottom')">
        Skos - 
    </div>
</div>

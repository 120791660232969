import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { updateVrElements } from './../../actions/vr-elements.actions';

@Component({
  selector: 'select-teleport',
  templateUrl: './select-teleport.component.html',
  styleUrls: ['./select-teleport.component.scss']
})
export class SelectTeleportComponent implements OnInit {

  vrgalleryImages$:Observable<object>
  selectedElement$:Observable<string>
  activeID:any = -1;

  constructor(private store: Store<{ vrgalleryImages: object, selectedElement: string }>) {
    this.vrgalleryImages$ = store.pipe(select('vrgalleryImages'));
    this.selectedElement$ = store.pipe(select('selectedElement'));
  }

  ngOnInit(): void {
    this.getSelectedTravel();

    this.selectedElement$.subscribe(() => {
        this.getSelectedTravel();
    });
  }

  getSelectedTravel(){
    let getActiveEl = document.querySelector('.selected');
    let selected = getActiveEl.getAttribute('data-travel');
    this.activeID = parseInt(selected);
  }

  setSelectedTravel(){
    let getActiveEl = document.querySelector('.selected');
    getActiveEl.setAttribute('data-travel', this.activeID.toString() );
  }
}

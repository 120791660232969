import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setImageBg } from './../../actions/image-bg.actions';
import { setImageId } from './../../actions/image-id.actions';
import { setDuplicating } from './../../actions/duplicating.actions';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import getDataFromUrl from './../../services/getDataFromUrl';

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

  imageBg$:Observable<string>
  imageId$:Observable<number>
  imgData$:Observable<object>
  vrgalleryImages$:Observable<object>

  constructor(private store: Store<{ imageBg: string, imageId: number, imgData: object, vrgalleryImages: object }>) { 
    this.imageBg$ = store.pipe(select('imageBg'));
    this.imageId$ = store.pipe(select('imageId'));
    this.imgData$ = store.pipe(select('imgData'));
    this.vrgalleryImages$ = store.pipe(select('vrgalleryImages'));
  }

  ngOnInit(): void {
    this.imgData$.subscribe(data => {
      if(data) {
        this.imgName = data['name'] ? data['name'] : '';
        this.visible = data['visible'] ? 'yes' : 'no';
        this.previewImage = data['preview_img'];
      }
    })

  }

  selectedImage = null;
  previewImage = null;
  uploadText = '';
  imgName = '';
  visible = "yes";
  duplicate = null;

  onFileSelected (e, type) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    if(type == "main") {
      reader.onload = this._handleReaderLoaded.bind(this);
    }else{
      reader.onload = this._handleReaderLoadedPreview.bind(this);
    }
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.selectedImage = reader.result;
  }

  _handleReaderLoadedPreview(e) {
    let reader = e.target;
    this.previewImage = reader.result;
  }

  refreshPage = () => {
    window.location.reload();
  }

  changeVisible = (e) => {
    this.visible = e.target.value;
  }

  duplicateScene = async () => {
    await this.store.dispatch(setDuplicating({ value: 'duplicating' }));
    this.uploadText = "Proszę poczekaj, właśnie wgrywamy Duplikat na serwer";
    let type, id, vrId, name;
    let token = getDataFromUrl('token');
    
    if(getDataFromUrl('localID')) {
      type = "city"
      id = getDataFromUrl('localID');
    }else if(getDataFromUrl('atractionID')) {
      type = "atraction"
      id = getDataFromUrl('atractionID');
    }
    vrId = this.duplicate.id;
    name = this.imgName;

    await axios({
      method: 'post',
      url: `${ApiUrl()}/createVrFromData`,
      data: {
        name: name,
        vrID: vrId,
        type: type,
        id: id,
        token: token
      }
    }).then(async response => {
      let imageId = response.data.data.id;
      this.uploadText = "Gotowe";
      let url = window.location.search;
      let params = new URLSearchParams(url)
      await params.set('srcLoad', imageId);
      window.location.search = params.toString();
      await setTimeout(async() => {
        await this.refreshPage();
      }, 500);
    }).catch(function (error) {
      console.log(error);
    })
    await this.store.dispatch(setDuplicating({ value: '' }));
  }

  changeImgName = (event) => {
    this.imgName = event.target.value;
  }

  onUpload = async () => {
    this.uploadText = "Proszę poczekaj, właśnie wgrywamy Twoje zdjęcie na serwer";
    var type, id;
    var token = getDataFromUrl('token');
    if(getDataFromUrl('localID')) {
      type = "city"
      id = getDataFromUrl('localID');
    }else if(getDataFromUrl('atractionID')) {
      type = "atraction"
      id = getDataFromUrl('atractionID');
    }
    var srcLoad = getDataFromUrl('srcLoad');

    await axios({
      method: 'post',
      url: `${ApiUrl()}/insertMainImageVr`,
      data: {
        img: this.selectedImage,
        type: type,
        id: id,
        srcLoad: srcLoad ? srcLoad : 0,
        previewImage: this.previewImage,
        visible: this.visible == "yes" ? 1 : 0,
        imgName: this.imgName,
        token: token
      }
    }).then(async response => {
      this.uploadText = "Gotowe";
      if(response.data.reload) {
        this.refreshPage();
      }else{
        await this.store.dispatch(setImageId({ name: response.data.srcLoad }));
        let url = window.location.search;
        let params = new URLSearchParams(url)
        params.set('srcLoad', response.data.srcLoad );
        window.location.search = params.toString();
      }
      if(response.data.url) {
        await this.store.dispatch(setImageBg({ name: response.data.url }));
      }
    }).catch(function (error) {
      console.log(error);
    })
  }
}

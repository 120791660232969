import { createReducer, on } from '@ngrx/store';
import { setVrElements, removeVrElements, updateVrElements } from './../actions/vr-elements.actions';

export const initialState = [];
 
const _vrElementsReducer = createReducer(initialState,
  on(setVrElements, (state, action) => [...state, action.obj]),
  on(removeVrElements, (state, action) => {
    let storageItems = JSON.parse(JSON.stringify(state))

    const i = storageItems.findIndex(x => x.id === action.id);
    storageItems.splice(i , 1);

    return storageItems;
  }),

  on(updateVrElements, (state, action) => {
    let storageItems = JSON.parse(JSON.stringify(state))

    const i = storageItems.findIndex(x => x.id === action.obj.id);
    storageItems.splice(i , 1);

    return [...storageItems, action.obj];
  }),

);


// const addNewItem = ( state, obj ) {
//   let storageItems = state;
//   console.log(stora)

    // if ( !storageItems ) {
    //   storageItems = [];
    //   storageItems.push(createJSON);
    // } else {
    //   storageItems = JSON.parse(storageItems);
    //   storageItems.forEach((el, i) => {
    //     if ( el.id === createJSON.id ) {
    //       storageItems.splice(i , 1);
    //     }
    //   });
    //   storageItems.push(createJSON);
    // }
    

    // sessionStorage.setItem('vrElements', JSON.stringify(storageItems));
    // console.log(sessionStorage)
// }

export function vrElementsReducer(state, action) {
    return _vrElementsReducer(state, action);
}
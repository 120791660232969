<div class="scene-container">
    <a-scene id="main-scene" *ngIf="imageBg$ | async" embedded inspector="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js">
        <!-- <a-scene embedded inspector="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js"> -->
        <a-sky src="#bg" rotation="0 0 0"></a-sky>
        <a-assets>
            <img id="info" src="./assets/img/info.png">
            <img id="pin" src="./assets/img/pin.png">
            <img id="bg" [src]="(imageBg$ | async)"/>
        </a-assets>
        <a-entity id="cameraWrapper" [attr.rotation]="cameraRotation">
            <a-camera>
                <a-cursor (click)="checkClicked()" rotation="0 0 0"></a-cursor>
                <a-entity id="clickPosition" position="0 0 -8" ></a-entity>
            </a-camera>
        </a-entity>
        <a-plane class="vr-el" *ngFor="let el of vrElements" [attr.position]="el.position" [attr.rotation]="el.rotation" [attr.opacity]="1" [attr.data-id]="el.id" [attr.data-type]="el.src" transparent="true" [attr.src]="'#' + el.src"  [attr.data-travel]=" el.travel ? el.travel : null" width="0.6" height="0.6" material="" geometry="" (click)="clickVrElement($event.target)"></a-plane>
    </a-scene>
    <div class="popup" *ngFor="let el of vrPopups" [attr.data-i]="el.id">
        <div class="popup-container">
            <div class="close-popup">X</div>
            <div class="image-container" [attr.data-id]="el.imageID" *ngIf="el.imageID">
                <img [src]="el.imageSrc">
            </div>
            <div class="text-container" [innerHTML]="el.text" *ngIf="el.text"></div>
        </div>
    </div>

</div>
<navigation>
</navigation>
<toolbar></toolbar>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { QuillModule } from 'ngx-quill';
import { CngHtmlCompilerModule } from '@codehint-ng/html-compiler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ToolbarComponent } from './components/toolbar/toolbar/toolbar.component';
import { OptionsElementsComponent } from './components/options-elements/options-elements/options-elements.component';

import { selectedElementReducer } from './reducers/selected-element.reducer';
import { duplicatingReducer } from './reducers/duplicating.reducer';
import { activeMenuReducer } from './reducers/active-menu.reducer';
import { imageBgReducer } from './reducers/image-bg.reducer';
import { imageIdReducer } from './reducers/image-id.reducer';
import { galleryImagesReducer } from './reducers/gallery-images.reducer';
import { coordinatesReducer } from './reducers/coordinates.reducer';
import { vrgalleryImagesReducer } from './reducers/vr-gallery-images.reducer';
import { vrElementsReducer } from './reducers/vr-elements.reducer';
import { uploadImgReducer } from './reducers/upload-img.reducer';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { SelectTeleportComponent } from './components/select-teleport/select-teleport.component';
import { ButtonComponent } from './components/button/button.component';
import { MovingComponent } from './components/moving/moving.component';
import { RotatingComponent } from './components/rotating/rotating.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { CoordinatesComponent } from './components/coordinates/coordinates.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    ToolbarComponent,
    OptionsElementsComponent,
    TextEditorComponent,
    SelectTeleportComponent,
    ButtonComponent,
    MovingComponent,
    RotatingComponent,
    UploadImageComponent,
    CoordinatesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CngHtmlCompilerModule,
    StoreModule.forRoot({ 
      selectedElement: selectedElementReducer, 
      activeMenu: activeMenuReducer, 
      imageBg: imageBgReducer, 
      imageId: imageIdReducer,
      galleryImages: galleryImagesReducer,
      vrgalleryImages: vrgalleryImagesReducer,
      vrElements: vrElementsReducer,
      imgData: uploadImgReducer,
      coords: coordinatesReducer,
      duplicating: duplicatingReducer
    }),
    QuillModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

<div class="toolbar" [ngClass]="{
                'big': (selectedElement$ | async) === 'info' && (activeMenu$ | async).length > 0,
                'open': (activeMenu$ | async) && (activeMenu$ | async).length > 0 }
                ">
    <options-elements *ngIf="(activeMenu$ | async) == 'new-element'"></options-elements>
    <text-editor *ngIf="(activeMenu$ | async) == 'edit' && (selectedElement$ | async) === 'info'"></text-editor>
    <select-teleport *ngIf="(activeMenu$ | async) == 'edit' && (selectedElement$ | async) === 'pin'"></select-teleport>
    <moving *ngIf="(activeMenu$ | async) == 'moving'"></moving>
    <rotating *ngIf="(activeMenu$ | async) == 'rotation'"></rotating>
    <upload-image *ngIf="(activeMenu$ | async) == 'upload'"></upload-image>
    <coordinates *ngIf="(activeMenu$ | async) == 'coords'"></coordinates>

</div>

import { createReducer, on } from '@ngrx/store';
import { setDuplicating } from './../actions/duplicating.actions';

export const initialState = '';
 
const _duplicatingReducer = createReducer(initialState,
  on(setDuplicating, (state, action) => action.value) ,
);

export function duplicatingReducer(state, action) {
    return _duplicatingReducer(state, action);
}
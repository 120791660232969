import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rotating',
  templateUrl: './rotating.component.html',
  styleUrls: ['./rotating.component.scss']
})
export class RotatingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  moveElement(direction) {
    // var status = 'selected';
    let getActiveEl = document.querySelector('.selected');
    if(!getActiveEl) {
      // status = 'camera';
      getActiveEl = document.querySelector('#cameraWrapper');
    }
    let getX = getActiveEl['object3D'].rotation.x * 57.2957795;
    let getY = getActiveEl['object3D'].rotation.y * 57.2957795;
    let getZ = getActiveEl['object3D'].rotation.z * 57.2957795;

    switch (direction) {
      case 'left': 
        getActiveEl.setAttribute('rotation', `${ getX - 5 } ${ getY } ${ getZ }`);
        break;

      case 'right':   
        getActiveEl.setAttribute('rotation', `${ getX + 5 } ${ getY } ${ getZ }`);
        break;

      case 'top':   
        getActiveEl.setAttribute('rotation', `${ getX } ${ getY + 5 } ${ getZ }`);
        break;

      case 'bottom':   
        getActiveEl.setAttribute('rotation', `${ getX } ${ getY - 5 } ${ getZ }`);
        break;

      case 'zoomin':   
        getActiveEl.setAttribute('rotation', `${ getX } ${ getY } ${ getZ + 5}`);
        break;

      case 'zoomout':   
        getActiveEl.setAttribute('rotation', `${ getX } ${ getY } ${ getZ - 5}`);
        break;
    
      default:
        break;
    }

  }
}

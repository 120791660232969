import { createReducer, on } from '@ngrx/store';
import { setVRGalleryImages } from './../actions/vr-images.actions';

export const initialState = [];
 
const _vrgalleryImagesReducer = createReducer(initialState,
  on(setVRGalleryImages, (state, action) => action.images) ,
);

export function vrgalleryImagesReducer(state, action) {
    return _vrgalleryImagesReducer(state, action);
  }
import { createReducer, on } from '@ngrx/store';
import { setActiveMenu, resetActiveMenu } from './../actions/active-menu.actions';

export const initialState = '';
 
const _activeMenuReducer = createReducer(initialState,
  on(setActiveMenu, (state, action) => action.name) ,
  on( resetActiveMenu, state => initialState),
);

export function activeMenuReducer(state, action) {
    return _activeMenuReducer(state, action);
}
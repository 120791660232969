import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {

  editorHtml = '';
  wantImage = false;
  activeImage = -1;
  activeImageSrc = '';

  galleryImages$:Observable<object>
  selectedElement$:Observable<string>


  constructor(private store: Store<{ galleryImages: object, selectedElement: string }>) {
    this.galleryImages$ = store.pipe(select('galleryImages'));
    this.selectedElement$ = store.pipe(select('selectedElement'));

  }

  ngOnInit(): void {
    this.getData();

    this.selectedElement$.subscribe(() => {
        this.getData();
    });
  }

  getData() {
    let getActiveEl = document.querySelector('.selected');

    if ( getActiveEl ) {
      let getSelectedDataId = getActiveEl.getAttribute('data-id');
      let getPopup = document.querySelector( `[data-i*="${ getSelectedDataId }"]` );
      
      if ( !getPopup )  {
        this.wantImage = false;
        this.activeImage = -1;
        this.activeImageSrc = '';
        this.editorHtml = '';

        return false;
      };

      let imageContainer = getPopup.querySelector('.image-container') ? getPopup.querySelector('.image-container') : null;
      let imageID = -1;
      let imageSrc = '';
      let textContainer = getPopup.querySelector('.text-container');
      let text = '';
      if ( imageContainer ) {
        imageID = parseInt(imageContainer.getAttribute('data-id'));
        imageSrc = imageContainer.querySelector('img').getAttribute('src');
      }
      if ( textContainer ) {
        text = textContainer.innerHTML;
      }

      if ( imageID > -1 ) {
        this.wantImage = true;
        this.activeImage = imageID;
        this.activeImageSrc = imageSrc;
      }
      if ( text.length > 0 ) {
        this.editorHtml = text;
      }

    }
    
  }

  editorElement = (event) => {
    this.editorHtml = event.html;
  }

  saveInformation = () => {
    this.generatePopup();
  }

  setActive = (id: number, src: string) => {
    this.activeImage = id; 
    this.activeImageSrc = src 
  }

  wantImageChange() {
    this.wantImage = !this.wantImage;
    if (!this.wantImage ) {
      this.activeImage = -1;
    }
    return false;
  }

  generateHtmlPopup() {
    let html = `
        <div class="popup-container">
          <div class="close-popup">X</div>
          ${ this.activeImage >= 0 ? 
            `<div class="image-container" data-id="${ this.activeImage }">
              <img src="${ this.activeImageSrc }" />
             </div>` : ''}
          
          ${ this.editorHtml.length > 0 ? 
          `<div class="text-container">
            ${ this.editorHtml }
           </div>` : ''}
        </div>
    `;
    return html;
  }

  updatePopup(el) {
    let html = this.generateHtmlPopup();
    el.innerHTML = html;
    return false;
  }

  async generatePopup() {
    let getScene = document.querySelector('.scene-container')
    let getActiveEl = document.querySelector('.selected');
    let getID = getActiveEl.getAttribute('data-id');

    let getPopup = document.querySelector( `[data-i*="${ getID }"]` );

    if ( getPopup ) {
      this.updatePopup(getPopup);
      return false;
    }
    
    let containerHtml = document.createElement('div');
    containerHtml.classList.add('popup');
    containerHtml.setAttribute('data-i', getID);
    containerHtml.innerHTML = this.generateHtmlPopup();
    getScene.appendChild(containerHtml);


  }

}

import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setImageBg } from './../../actions/image-bg.actions';
import { setImageId } from './../../actions/image-id.actions';
import { set, reset } from './../../actions/selected-element.actions';
import { setVrElements } from './../../actions/vr-elements.actions';
import { setUploadImg } from './../../actions/upload-img.actions'; 
import { resetActiveMenu } from './../../actions/active-menu.actions';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import getDataFromUrl from './../../services/getDataFromUrl';
import { setGalleryImages } from './../../actions/gallery-images.actions';
import { setVRGalleryImages } from './../../actions/vr-images.actions';
import { setCoordinates } from 'src/app/actions/coordinates.actions';

var url = window.location.href

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imageBg$:Observable<string>
  imgData$:Observable<object>

  constructor(private store: Store<{ imageBg: string, imgData: object }>) { 
    this.imageBg$ = store.pipe(select('imageBg'));
    this.imgData$ = store.pipe(select('imgData'));
  }

  menuActive: string;
  selectedItemType: string;
  vrElements: Array<object>;
  vrPopups: Array<object>;
  cameraRotation: string = "0 0 0";


  handleMenuActiveValue( value: string) {
    this.menuActive = value;
  }

  checkClicked() {
    let getSelectedItem = document.querySelector('.selected');
    if ( getSelectedItem ) {
      this.selectedItemType = getSelectedItem.getAttribute('data-type');
    } else {
      this.selectedItemType = '';
    }
  }

  ngOnInit(): void {
    this.getMainImageVr();
    this.getDataPins();
  }

  getMainImageVr = async () => {
    var srcImg = getDataFromUrl('srcLoad');
    var token = getDataFromUrl('token');
    if(srcImg !== "0")  {
      await this.store.dispatch(setImageId({ name: Number(srcImg) }));
      await axios({
        method: 'post',
        url: `${ApiUrl()}/getMainImageVr`,
        data: {
          imageID: srcImg,
          token: token
        }
      }).then(async response => {
          await this.store.dispatch(setImageBg({ name: response.data.main_img_url }));
          await this.store.dispatch(setUploadImg({ obj: response.data }));
          if ( response.data.content ) {
            let data = JSON.parse( response.data.content )

            if ( data.pins )
              this.vrElements = data.pins;

            if ( data.popups )
              this.vrPopups = data.popups;

            if ( data.cameraRotation )
              this.cameraRotation = data.cameraRotation;
              
          }
          await this.store.dispatch(setCoordinates({ coords: { lat: response.data.lat, lng: response.data.lng } }));
      })
    }
  }

  clickVrElement = async(el) => {
    let type = el.dataset.type;
    if ( !el.classList.contains('selected') ) {
      await this.store.dispatch(set({ name: 'pusto' } ));
      await this.deleteSelectedElements();
      await el.classList.add('selected');
      await el.setAttribute( 'opacity', '0.80');
      await this.store.dispatch(set({ name: type } ));
    } else {
      await el.classList.remove('selected');
      await el.setAttribute( 'opacity', '1');
      await this.store.dispatch(resetActiveMenu());
      await this.store.dispatch(reset());
    }
  }

  deleteSelectedElements() {
    let selected = document.querySelectorAll('.selected');
    if ( selected.length > 0 ) {
      selected.forEach(el => {
        el.classList.remove('selected');
        el.setAttribute( 'opacity', '1');
        
      })
    }
  }

  getDataPins = async () => {
    var postType;
    var postID;
    var token = getDataFromUrl('token');
    if((window.location.href).includes('atractionID')) {
      postType = 'atraction';
      postID = getDataFromUrl('atractionID');
    }else{
      postType = 'city';
      postID = getDataFromUrl('localID');
    }

    await axios({
      method: 'post',
      url: `${ApiUrl()}/getImagesFromPost`,
      data: {
        postType: postType,
        postID: postID,
        token: token
      }
    }).then(async response => {

      let vrImages = [];
      let images = [];
      if ( response.data.length > 0 ) {
        for (let i = 0; i < response.data.length; i++) {
          const el = response.data[i];
          if ( el.type === 'vr') {
            vrImages.push(el);
          } else {
            images.push(el);
          }
        }
      } 
      await this.store.dispatch(setGalleryImages({ images: images}));
      await this.store.dispatch(setVRGalleryImages({ images: vrImages}));

    })
  }

}
import { createReducer, on } from '@ngrx/store';
import { setGalleryImages } from './../actions/gallery-images.actions';

export const initialState = [];
 
const _galleryImagesReducer = createReducer(initialState,
  on(setGalleryImages, (state, action) => action.images) ,
);

export function galleryImagesReducer(state, action) {
    return _galleryImagesReducer(state, action);
  }